@import "../../styles.scss";

.job-details {
    padding: 20px;
    border-top: 2px solid #E5E7EB;

    h3 {
        margin-bottom: 10px;
    }

    .nav-tabs {
        margin-bottom: 15px;
    }

    .tab-content {
        padding: 15px;
    }
}

// Custom Tabs
.custom-tabs .tab-titles {
    display: flex;
    justify-content: flex-start;
    background-color: white;
    padding-bottom: 10px;
}

.custom-tabs .tab-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: $gray;
    padding: 10px 20px;
    cursor: pointer;
}

.custom-tabs .tab-title.active {
    color: $primary;
    border-bottom: 2px solid $primary;
}

.custom-tabs .tab-title:hover {
    color: $primary;
}

.custom-tabs .tab-content {
    margin-top: 20px;
    padding: 10px;
}

.custom-tabs .tab-pane {
    display: block !important;
    box-shadow: 0px 10px 15px -3px #0000001A;
}

// Custom select for JD dropdown
.custom-select {
    border: unset !important;
    width: auto !important;
    font-family: "Inter", sans-serif;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 30px !important;
    letter-spacing: 0.4px;
    color: $gray !important;
    cursor: pointer;
    min-width: 230px !important;
}

.tab-title.active .custom-select {
    color: $primary !important;
}

// Full-width grey bar styles
.grey-bar {
    width: 100%;
    background-color: $bg-tertiary; // Light grey color for the bar
    min-height: 76px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 20px;
}

.jd-version-dropdown {
    .dropdown {
        padding: 5px 30px 5px 0;
        border: unset;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.4px;
        background-color: tertiary !important;
    }
}

.icon-group {
    display: flex;
    gap: 5px; // Space between icons

    .icon {
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: transform 0.3s ease, fill 0.3s ease;

        &:hover {
            transform: scale(1.2); // Scale on hover for interaction effect
        }
    }
}

// Job description container styles
.job-description-container {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 18px;
}

// CSS for tooltip for icons
.icon-wrapper {
    position: relative;
    display: inline-block;
}

.icon-wrapper:hover::after {
    content: attr(title);
    position: absolute;
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 12px;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s;
}

.icon-wrapper::after {
    visibility: hidden;
    opacity: 0;
}

.job-description-html {

    text-align: left;
    max-width: 85vw;
    padding: 16px 31px;

    h1,
    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 155%;
        letter-spacing: 0.15px;
    }

    h3,
    h4,
    h5,
    h6,
    th {
        font-weight: 700;
        font-size: 18px;
        line-height: 155%;
        letter-spacing: 0.15px;
    }

    p,
    ul,
    li,
    td,
    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        letter-spacing: 0.15px;
        color: $gray !important;
    }

}

.eval-box {
    background-color: #fff;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.eval-box .score-label {
    font-weight: 500;
    font-size: 16px;
    color: #2B3034;
    margin-bottom: 15px;
    text-align: left;
}

.eval-box .dropdown-menu {
    position: static;
    background-color: white;
    border: unset;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 20px;
    width: 100%;
    display: block !important;
}

.eval-box .dropdown-menu .dropdown-menu-items {
    padding: 8px 0;
    border-bottom: 1px solid #f1f1f1;
    font-weight: 400;
    font-size: 16px;
}

.eval-box .dropdown-menu .dropdown-menu-items:last-child {
    border-bottom: none;
}

.eval-box .dropdown-menu .dropdown-menu-items:hover {
    background-color: #f1f1f1;
}

.green-bg {
    background-color: $success !important;
    /* Green for increase */
    color: #fff;
}

.blue-bg {
    background-color: $secondary;
    /* Blue for no change */
}

.red-bg {
    background-color: $danger !important;
    /* Red for decrease */
    color: #fff;
}

/* General button styling */
.dropdown-btn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
}

/* Progress Circle Styling */
.progress-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 5px solid #e6f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
    text-align: center;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    transition: all 0.3s ease;
    background-color: $primary;
    letter-spacing: 0;
    padding-left: 5px;
}



// diff checker css 

.custom-form-group {
    width: 320px;
    text-align: left;
}

.custom-form-group {
    position: relative;
    margin: 40px 0 28px;

    label {
        position: absolute;
        top: -0.6rem;
        left: 10px;
        background: $white;
        padding: 0 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    select {
        border: 1px solid #1F2937;
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    select option {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Inter', sans-serif;
    }
}

/* VS Separator Styling */
.vs-row {
    display: flex;
    align-items: center;
    padding: 15px 0;
}

.vs-line {
    flex: 1;
    height: 1px;
    background-color: #E5E7EB;
}

.vs-box {
    padding: 5px 15px;
    background: #ddeaea;
    font-weight: bold;
    border-radius: 35px;
    text-align: center;
    width: 80px;
    height: 33px;
    padding-top: 6px;
    padding-right: 24px;
    padding-bottom: 6px;
    padding-left: 24px;
    display: block;
}

//hiding the editor buttons 
.button.button-type-fullscreen,
.button.button-type-mode,
.button.button-type-code-inline,
.button.button-type-code-block,
.button.button-type-table,
.button.button-type-image,
.button.button-type-link,
.button.button-type-clear {
    display: none !important;
}

.rc-md-editor .rc-md-navigation {
    width: 50%;
}

.rc-md-editor.markdown-editor {
    max-height: 60vh;
    overflow-y: scroll;
}

.rc-md-editor.markdown-editor {
    overflow: auto;
    /* Allow scrolling */
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer 10+ */
}

.rc-md-editor.markdown-editor::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, Edge */
}


// sizing for evaluation popup
.eval-pop-up {
    left: 50%;
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 48vw !important;
    min-height: 27vw !important;
    height: auto !important;
    display: none;

    .drawer-body {
        padding: 34px 75px;
        text-align: left;
        overflow-y: hidden;
        height: unset;
    }

    .table {
        margin: 0 auto;
        width: 100%;
    }

    .vs-box {
        font-weight: 500;
        margin: 0 auto;
    }

    .bg-tertiary {
        text-align: center;
    }

}

.drawer.eval-pop-up.open {
    display: block !important;
}


.unset-bg {
    background-color: unset !important;
    font-weight: 600 !important;
    width: 100% !important;
    padding: 20px 0;
    display: block;
}

// css for comparision summary pop-up 

.summary-pop-up {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75vw;
    display: none;
    overflow: hidden;
    max-height: 90vh;
    transition: right 0.3s ease-in-out;
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1050;
    background-color: #525659;

}

.summary-pop-up.open {
    display: block;
}

.summary-header {
    background-color: #323639;
    color: $white;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 56px;
}

.summary-body {
    width: 100%;
    max-width: 990px;
    margin: 0 auto;
    max-height: 80vh;
    background-color: $white;
    overflow: auto;

    td {
        padding: 40px 31px;
        text-align: left;
        border-bottom: 1px solid $border-color;
        vertical-align: baseline;
        color: $dark;
        font-weight: 500;
    }

    th {
        padding: 14px 31px;
        text-align: left;
        vertical-align: baseline;
        color: #000;
        font-weight: 500;
    }

    ul,
    li {
        color: $dark;
        font-weight: 500;
        padding-left: 0;
        font-size: 14px;
        line-height: 16px;
    }
}

.summary-body::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, Edge */
}


.summary-file {
    color: $white;
}

.summary-zoom-percent {
    width: 51px;
    color: #fff;
    background: #191B1C;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
}

.summary-pop-up.open.full-modal {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: unset;
    overflow: auto;
    max-height: unset;

    .summary-body {
        max-width: unset;
        max-height: unset;
    }

    .summary-body td,
    .summary-body th,
    ul,
    li {
        font-size: 18px;
        line-height: 22px;
    }
}

.summary-table-holder {
    --bs-gutter-x: 0 !important;
}


.blurred-content {
    white-space: pre-wrap;
    text-overflow: ellipsis;
    // max-height: 250vh;
    overflow: hidden;
    /* Multi-step gradient mask */
    mask: linear-gradient(to bottom,
            black 25%,
            /* 0% to 25% visible */
            rgba(0, 0, 0, 0.5) 50%,
            /* 25% to 50% partially visible (medium opacity) */
            transparent 100%
            /* 50% to 100% completely hidden */
        );
    -webkit-mask: linear-gradient(to bottom,
            black 25%,
            rgba(0, 0, 0, 0.2) 50%,
            transparent 100%);
    /* For Safari */

    /* Apply the filter for blurring effect */
    backdrop-filter: blur(0px);
    /* No blur at the top */
    -webkit-backdrop-filter: blur(0px);
    /* For Safari */

    filter: blur(0px);
    /* Apply blur only on the bottom part */
}

.free-trial-exhausted-popup {
    box-shadow: 0px 12px 40px 0px #1018403D;
    width: 512px;
    margin: 0 auto;
    border-radius: 8px;
    padding: 32px;
    position: relative;
    bottom: 200px;
    background: #fff;
    text-align: left;

    h5,
    p {
        margin-bottom: 15px;
    }

    p {
        line-height: 18px;
    }

}

.disclaimer-italics {
    font-style: italic;
}