@import "../../styles.scss";

.jobs-heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.jobs-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.desc-text {
  width: 100%;
  max-width: 800px;
}

.jobs-table-header th {
  background-color: #E5E7EB;
}

.jobs-table-heading th,
.jobs-table-content td {
  font-weight: 500;
  font-size: 14px;
  line-height: 16.94px;
  color: $dark !important;
}

.jobs-table-content td {
  letter-spacing: 0px;
  vertical-align: middle;
  background-color: unset !important;
  text-align: left;
}

.jobs-table-content td:hover {
  text-decoration: unset !important;
}

.jobs-table-content td.jobs-table-title {
  color: #0088CE !important;
  text-align: left;
}

.jobs-table-critical-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // Optional: Ensure it takes full height of the table cell

  .form-check-input {
    width: 24px; // Set width to 24px
    height: 24px; // Set height to 24px
  }
}

// Hover effect and tooltip
.jobs-table-content {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.jobs-table-content:hover {
  background-color: #EAECF0 !important;
}

/* Style for the tooltip on hover (title attribute) */
.jobs-table-content[title]:hover,
.task-automation-potential[title]:hover,
.form-check-input[title]:hover {
  position: relative;
}

/* Custom tooltip styling (shape resembling a message) */
.jobs-table-content[title]:hover::after,
.task-automation-potential[title]:hover::after,
.form-check-input[title]:hover::after {
  content: attr(title);
  /* Display the title attribute */
  position: absolute;
  bottom: 100%;
  /* Position above the row */
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  background-color: #333;
  /* Dark background for the message */
  color: white;
  border-radius: 15px;
  /* Rounded corners for a message bubble effect */
  font-size: 12px;
  white-space: nowrap;
  opacity: 0.9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  /* Slight shadow for depth */
  z-index: 10;
}


.jd-in-progress-row {
  background-color: #ddeaea !important;
  /* Blue */
  border: 2px solid #008080;
}

.jd-success-row {
  background-color: #C4FFC4;
  border: 2px solid #10B84D;
}

// Styling for "No JD" rows
.no-jd-row {
  background-color: #FFFAF1;
  border: 2px solid #FFB020;
}

.no-jd-text {
  color: #DC2626;
  font-weight: bold;
  font-size: 16px;
}

.buttom-button-bar {
  box-shadow: 0px -4px 20px 0px #0000001A;
  background: #FFFFFF;
  height: 71px;
}

.eval-table-content td {
  border: unset;
  vertical-align: middle;
}